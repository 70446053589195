import React, { useEffect, useState } from "react";
import "./App.css";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import img from "./img-4.webp";
import { useAccount, useDisconnect } from "wagmi";
import { abi, contractAddress } from "./abi";
import Web3 from "web3";
function App() {
  const [loading, setLoading] = useState(false);
  const [claimBalance, setClaimBalance] = useState("N/A");
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  useEffect(() => {
    if (address && isConnected) {
      fetchBalance();
    }
  }, [address, isConnected]);

  async function claim() {
    setLoading(true);
    try {
      let web3;

      if (typeof window.ethereum !== "undefined") {
        let dsds = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(dsds);
        web3 = new Web3(window.ethereum);
      } else {
        console.log("MetaMask not found. Using custom RPC endpoint.");
        web3 = new Web3(
          new Web3.providers.HttpProvider(
            "https://thrilling-palpable-field.quiknode.pro/8a2a98d983ed370d22121d9f1737b8c20d1ffa44/"
          )
        );
      }
      const contract = new web3.eth.Contract(abi, contractAddress);
      const accounts = await web3.eth.getAccounts();
      await contract.methods.claimDividend().send({ from: accounts[0] });
      fetchBalance();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  async function fetchBalance() {
    try {
      const web3 = new Web3(
        new Web3.providers.HttpProvider(
          "https://thrilling-palpable-field.quiknode.pro/8a2a98d983ed370d22121d9f1737b8c20d1ffa44/"
        )
      );
      const contract = new web3.eth.Contract(abi, contractAddress);
      const unpaidEarnings = await contract.methods
        .getUnpaidEarnings(address)
        .call();
      console.log(unpaidEarnings);
      setClaimBalance(Number(unpaidEarnings) / 1e8);
    } catch (e) {
      console.log(e);
    }

    // const nftContract = new ethers.Contract(contractAddress, abi, provider);
    // toast.warn("Initialize minting");
    // let depoTxn = await nftContract.mint(account, amount, {
    //   value: ethers.formatEther(amount),
    // });
  }

  return (
    <div className="main-div">
      <div className="form-dap">
        <h1>Collect Rewards</h1>

        <div className="form-dap-control">
          <img src={img} />
          <label>You are eligible for: </label>
          <input
            placeholder={isConnected ? "0" : "-"}
            readOnly
            value={claimBalance}
          />
          {isConnected ? (
            <button onClick={() => claim()}>
              {loading ? "Please Wait" : "Claim"}
            </button>
          ) : (
            <button onClick={() => open()}>Connect Wallet</button>
          )}
          {isConnected ? (
            <span onClick={() => disconnect()}>Disconnect</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
